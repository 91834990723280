@choice_nav_btn_color : #009688;
@head_nav_bg_color : #23262E ;
@side_nav_bg_color : #393D49;
@head_search_height : 2rem;

@body_bg_color : #fafafa;

// 最外层大div
div.my-container {
  // head -- 查询 -- 下拉框
  select.search-type {
    text-align-last: center;
    color: #000;
    width: 5.8rem;
    height: @head_search_height;
    margin-right: 1rem;
    margin-top: 2rem;
  }
  // head -- 查询 -- 输入框
  li .layui-input {
    height: @head_search_height;
  }
  // head -- 分类 -- 大类 -- 按钮底色 -- 未选中
  .layui-btn {
    background-color: #23262E;
    margin:0 0.2rem;
  }
  // head -- 分类 -- 大类 -- 按钮底色 -- 鼠标hover
  .layui-btn:hover {
    background-color: @side_nav_bg_color;
    color: white;
  }
  // head -- 分类 -- 大类 -- 按钮底色 -- 选中
  .layui-btn-my_choice {
    background-color: @choice_nav_btn_color !important;
    border: #fff 0 solid;
  }
  // head -- 分类 -- 大类 -- 按钮边框 -- 点下后的效果
  button:focus{
    outline: 5px auto @choice_nav_btn_color;
  }


  // 左侧 -- 分类 -- 二级 -- 选中项背景色
  .choice_second {
    background-color: @choice_nav_btn_color !important;
    color: #fff;
  }
  // 左侧 -- 分类 -- 二级 -- 正常样式
  .panel-default>.panel-heading {
    border: none;
    color: #fff;
    background-color: @head_nav_bg_color;
    background-image: none;
  }
  // 左侧 -- 分类 -- 二级 -- 背景色 正常样式
  .panel{
    margin: 3px 0 0 0;
    background-color: @side_nav_bg_color;
  }
  // 左侧 -- 分类 -- 二级 -- 背景色 正常样式
  .panel-default {
    border-color: @side_nav_bg_color !important;
  }
  // 左侧 -- 分类 -- 二级 -- 标题hover效果
  .panel .side-title:hover {
    color: #aaa
  }
  // 左侧 -- 分类 -- 三级 -- 选中景色
  .choice_article {
    color: @choice_nav_btn_color;
  }
  // 左侧 -- 分类 -- 三级 -- 缩进
  .nav>li>a{
    text-decoration: none;
    padding: 10px 10px 10px 35px;
  }

  // 正文 -- body
  div.layui-body {
    background-color: @body_bg_color;
    // 正文 -- body -- 上方定位面包屑
    .my-crumbs {
      display: flex;
      align-items: center;
      background-color: @body_bg_color;
      a {
        color: #1b6d85;
      }
    }
  }


}


// 左侧导航 -- 排列方式 -- 纵向
.my-left-side-ul {
  display: flex;
  flex-direction: column;
}

// 左侧导航 -- 鼠标hover颜色
.my-left-side-ul>li>a:hover {
  background-color: @head_nav_bg_color !important;
  color: white;
}

// 左侧导航 -- 鼠标点击颜色
.my-left-side-ul>li>a:focus {
  color: @choice_nav_btn_color;
  background-color: @side_nav_bg_color;
}






a,a:focus,a:hover{
  color: #fff;
  text-decoration: none;
}

.panel-group .panel{
  overflow-y: auto;
  border: none;
}

.panel-group .panel {
  border: none;
}


.panel-default>.panel-heading>span{
  font-size: 10px;
}
.panel-default>.panel-heading:active,.panel-default>.panel-heading:hover{
  background-color: #4a8bc2;
}
//.panel-default>.panel-heading>a:hover{
//  text-decoration: none;
//  background-color: #4a8bc2;
//}
.panel-group .panel-heading+.panel-collapse>.panel-body {
  border: none;
}
.panel-body {
  padding: 0;
}
.nav>li{
  padding: 1px 0 0 0;
}




